import React, { useState } from "react";
import styled from "styled-components";
import theme from "theme";

const Menu = styled.div`
  width: 100%;

  ${theme.screens.lgScreen} {
    margin-bottom: 60px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  ${theme.screens.smScreen} {
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const MenuTitle = styled.div`
  font-size: 20px;
  width: 100%;
  font-weight: 500;
  cursor: pointer;
  color: ${theme.colors.textColor};
`;

const MenuSubtitle = styled.div`
  font-size: 14px;
`;

const MenuItem = styled.div`
  border-bottom: 1px solid #cccccc;
  padding: ${({ variant }) => (variant === "bigMenu" ? "20px 0" : "55px 0")};

  ${({ active }) =>
    active &&
    `
  ${MenuTitle} {
    color: ${theme.colors.pink}
  }
 `}
  ${theme.screens.xlScreen} {
    font-size: 18px;
    padding: 25px 0;
  }

  ${theme.screens.lgScreen} {
    width: auto;
    padding: 0 0 5px;
    margin-right: 30px;
    margin-bottom: 30px;
    text-align: center;
    font-size: 16px;
  }

  ${theme.screens.smScreen} {
    padding-bottom: 30px;
  }

  ${theme.screens.smScreen} {
    text-align: left;
  }

  &:first-of-type {
    padding: 0 0 55px 0;

    ${theme.screens.xlScreen} {
      padding: 0 0 25px 0;
    }

    ${theme.screens.lgScreen} {
      padding: 0 0 5px 0;
    }

    ${theme.screens.smScreen} {
      padding-bottom: 30px;
    }
  }

  &:last-of-type {
    padding: ${({ variant }) =>
      variant === "bigMenu" ? "20px 0 0 0" : "55px 0 0 0"};
    border-bottom: none;

    ${theme.screens.xlScreen} {
      padding: 25px 0 0 0;
    }

    ${theme.screens.lgScreen} {
      padding: 0 0 5px;
      border-bottom: 1px solid #cccccc;
    }

    ${theme.screens.smScreen} {
      border-bottom: none;
    }
  }
`;

const VerticalMenu = ({ items, handleSelectedItem, variant }) => {
  const firstItem = items[Object.keys(items)[0]];
  const [selectedItem, setSelectedItem] = useState(firstItem.key);
  return (
    <Menu>
      {Object.values(items).map((item, index) => {
        return (
          <MenuItem
            variant={variant}
            key={index}
            onClick={(e) => {
              setSelectedItem(item.key);
              handleSelectedItem(item.key);
            }}
            active={selectedItem === item.key}
          >
            <MenuTitle
              dangerouslySetInnerHTML={{
                __html: item.menuLabel ? item.menuLabel : item.name,
              }}
            />
            {selectedItem === item.key && (
              <MenuSubtitle
                dangerouslySetInnerHTML={{ __html: item.subtitle }}
              />
            )}
          </MenuItem>
        );
      })}
    </Menu>
  );
};

export default VerticalMenu;
