import "styles/pages/page-benefits.scss";
import React from "react";
import Layout from "components/Layout";
import Seo from "components/Seo";
import SubpageHeader from "components/SubpageHeader";
import Banner from "components/Banner";
import WhiteTile from "components/WhiteTile";
import SectionHeading from "components/SectionHeading/";
import TitleWithLine from "components/TitleWithLine";
import SectionWithMenu from "../components/SectionWithMenu";
import theme from "theme";

const selectableContent1_video_options = (key) => {
  switch (key) {
    case 0:
      return require("assets/videos/benefits-revenue.mp4").default;
    case 1:
      return require("assets/videos/benefits-cost.mp4").default;
    case 2:
      return require("assets/videos/benefits-efficiency.mp4").default;
    case 3:
      return require("assets/videos/benefits-decision.mp4").default;
    default:
      return null;
  }
};

const BenefitsPage = ({ pageContext }) => {
  const benefits = pageContext.pageContent;

  const selectableContent1 = benefits.sectionRcemMenuItems.map(
    (item, index) => ({
      key: index,
      menuLabel: item.menuLabel,
      name: item.name,
      desc: item.desc,
      image: item.image?.sourceUrl,
      video: selectableContent1_video_options(index),
    })
  );

  const selectableContent2 = benefits.sectionCwsuMenuItems.map(
    (item, index) => {
      return {
        key: index,
        menuLabel: item.menuLabel,
        name: item.name,
        desc: item.desc,
        image: item.image?.sourceUrl,
      };
    }
  );

  const selectableContent3 = benefits.sectionAudMenuItems.map((item, index) => {
    return {
      key: index,
      menuLabel: item.menuLabel,
      name: item.name,
      desc: item.desc,
      video: require("assets/videos/benefits-benefits.mp4").default,
    };
  });

  const accordionItems = benefits.sectionAhaiAccordionItems.map(
    (item, index) => {
      return {
        key: index,
        menuLabel: item.sectionAhaiAccordionItemTitle,
        name: item.sectionAhaiAccordionItemTitle,
        desc: item.sectionAhaiAccordionItemText,
        image: item.sectionAhaiAccordionItemImage?.sourceUrl,
      };
    }
  );

  return (
    <Layout>
      <Seo
        title={
          pageContext.seo.title ? pageContext.seo.title : pageContext.title
        }
        description={pageContext.seo?.description}
      />
      <SubpageHeader
        title={pageContext.title}
        image={require("assets/images/benefits-bg.jpg").default}
      />
      <Banner
        title={benefits.benefitsDivider1Title}
        description={benefits.benefitsDivider1Text}
      />
      <section className="benefits-solution">
        <div className="container">
          <SectionHeading title={benefits.sectionOsnbTitle} />
          <div className="row">
            {benefits.sectionOsnbTiles.map((tile, index) => {
              return (
                <div className="col-xl-3 col-md-6" key={index}>
                  <WhiteTile>{tile.sectionOsnbTileText}</WhiteTile>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <section className="benefits-improving">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="benefits-improving__content">
                <TitleWithLine variantY="bottom">
                  {benefits.sectionIcrTitle}
                </TitleWithLine>
                <p
                  className="benefits-improving__desc"
                  dangerouslySetInnerHTML={{ __html: benefits.sectionIcrText }}
                />
              </div>
            </div>
            <div className="col-md-6 offset-md-1">
              <img
                src={benefits.sectionIcrImage?.sourceUrl}
                alt=""
                className="img-fluid benefits-improving__img"
              />
            </div>
          </div>
        </div>
      </section>
      <SectionWithMenu
        items={selectableContent1}
        className="benefits-graphs"
        variant="imgRight"
      />
      <section className="benefits-data">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <img
                src={benefits.sectionEdiImage?.sourceUrl}
                alt=""
                className="img-fluid"
              />
            </div>
            <div className="col-xl-5 col-md-6">
              <div className="benefits-data__content">
                <TitleWithLine variantY="bottom" color="#fff">
                  {benefits.sectionEdiTitle}
                </TitleWithLine>
                <div className="benefits-data__desc">
                  {benefits.sectionEdiText}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <SectionWithMenu
        items={selectableContent2}
        variant="imgSingle"
        bgColor={theme.colors.light}
      />
      <section className="benefits-access">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <TitleWithLine variantY="bottom">
                {benefits.sectionQatkTitle}
              </TitleWithLine>
              <div className="benefits-access__desc">
                {benefits.sectionQatkText}
              </div>
            </div>
            <div className="col-md-6 offset-md-1">
              <img
                src={benefits.sectionQatkImage?.sourceUrl}
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </section>
      <SectionWithMenu items={selectableContent3} variant="imgSingle" />
      <section className="benefits-banner">
        <Banner
          title={benefits.benefitsDivider2Title}
          description={benefits.benefitsDivider2Text}
          size="small"
        />
      </section>
      <section className="benefits-accordion">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <SectionWithMenu items={accordionItems} variant="bigMenu" />
            </div>
            {/* <div className="col-lg-4">
              <img
                src={benefits.sectionAhaiCopy?.sourceUrl}
                alt=""
                className="img-fluid benefits-accordion__img"
              />
            </div> */}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default BenefitsPage;
