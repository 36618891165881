import classNames from "classnames";
import React, { useState } from "react";
import styled from "styled-components";
import VerticalMenu from "../VerticalMenu";
import TitleWithLine from "../TitleWithLine";
import PropTypes from "prop-types";
import theme from "theme";

const Section = styled.section`
  padding: 150px 0;
  background-color: ${({ bgColor }) => bgColor};

  ${theme.screens.lgScreen} {
    padding: 100px 0;
  }
`;

const Title = styled.h3`
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 45px;
`;

const Image = styled.img`
  display: block;
  margin: 60px auto 0;
`;

const ImageNoMg = styled.img`
  display: block;
  margin: 0 auto;
  border-radius: 16px;
  ${theme.screens.mdScreen} {
    margin: 30px auto;
  } ;
`;

const SingleImage = styled.img`
  display: block;
  margin: 60px 0 0 0;
`;

const Content = styled.div`
  ${theme.screens.xlScreen} {
    padding: 0 30px;
  }

  ${theme.screens.lgScreen} {
    padding: 0;
  }
`;

const SectionWithMenu = ({ items, className, variant, bgColor }) => {
  const firstItem = items[Object.keys(items)[0]];
  const [selectedItem, setSelectedItem] = useState(firstItem.key);
  const handleSelectedItem = (item) => {
    setSelectedItem(item);
  };

  return (
    <Section className={className} bgColor={bgColor}>
      <div className="container">
        {/* <div className="row g-0"> */}
        <div
          className={classNames(`row`, {
            [`g-0`]:
              variant === "normal" ||
              variant === "imgSingle" ||
              variant === "imgRight",
          })}
        >
          <div
            className={classNames({
              [`col-lg-3`]: variant !== "bigMenu",
              [`col-lg-5`]: variant === "bigMenu",
            })}
          >
            <VerticalMenu
              items={items}
              handleSelectedItem={handleSelectedItem}
              variant={variant}
            />
          </div>
          <div
            className={classNames(``, {
              [`col-xl-8 col-lg-9 offset-xl-1`]:
                variant === "normal" || variant === "imgSingle",
              [`col-xl-4 col-lg-6 offset-xl-1`]: variant === "imgRight",
              [`col-xl-5 col-lg-6`]: variant === "imgLeft",
              [`col-xl-6 col-lg-7 offset-xl-1`]: variant === "bigMenu",
            })}
          >
            {variant === "imgSingle" || variant === "bigMenu" ? (
              <Content>
                {/* <Title>{items[selectedItem].name}</Title> */}
                <div
                  dangerouslySetInnerHTML={{
                    __html: items[selectedItem].desc,
                  }}
                />
                {!!items[selectedItem]?.video ? (
                  <video
                    key={items[selectedItem]?.video}
                    autoPlay={true}
                    muted={true}
                    loop={true}
                    playsInline={true}
                    style={{
                      width: "100%",
                    }}
                  >
                    <source src={items[selectedItem]?.video} type="video/mp4" />
                  </video>
                ) : (
                  <SingleImage
                    className="img-fluid"
                    src={items[selectedItem].image}
                  />
                )}
              </Content>
            ) : (
              ""
            )}

            {variant === "imgLeft" && (
              <Content>
                <ImageNoMg
                  className="img-fluid"
                  src={items[selectedItem].image}
                />
              </Content>
            )}

            {variant === "imgRight" && (
              <Content>
                <Title>{items[selectedItem].name}</Title>
                <div
                  dangerouslySetInnerHTML={{ __html: items[selectedItem].desc }}
                />
              </Content>
            )}
          </div>
          {variant === "imgRight" && (
            <div className="col-xl-4 col-lg-3">
              {!!items[selectedItem]?.video ? (
                <video
                  key={items[selectedItem]?.video}
                  autoPlay={true}
                  muted={true}
                  loop={true}
                  playsInline={true}
                  style={{
                    width: "100%",
                    maxHeight: 380,
                    margin: "0 auto",
                    padding: "40px 0px 0 50px",
                  }}
                >
                  <source src={items[selectedItem]?.video} type="video/mp4" />
                </video>
              ) : (
                <Image
                  src={items[selectedItem].image}
                  alt=""
                  className="img-fluid"
                />
              )}
            </div>
          )}
          {variant === "imgLeft" && (
            <div className="col-xl-4 col-lg-3">
              <Content>
                <TitleWithLine variantX="left" variantY="bottom">
                  {items[selectedItem].name}
                </TitleWithLine>
                <div
                  dangerouslySetInnerHTML={{ __html: items[selectedItem].desc }}
                />
              </Content>
            </div>
          )}
        </div>
      </div>
    </Section>
  );
};

SectionWithMenu.propTypes = {
  variant: PropTypes.oneOf([`normal`, `imgRight`, `imgSingle`, `bigMenu`]),
};

SectionWithMenu.defaultProps = {
  variant: "normal",
};

export default SectionWithMenu;
